import * as iconLibrary from '@hah/icons';
import { Icon, useShowHide } from '@hah/shared';
import { PickSetState, formatCurrency } from '@hah/utils';
import * as React from 'react';
import { Button, Card, Col, Modal, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { DiscountComponent } from './DiscountComponent';

type Props = {
    jobs: readonly models.CheckoutPaymentStepJobViewModel[];
    payNow: boolean;
    pageVm: models.CheckoutPaymentStepReactViewModel;
    handleChangeVm: (change: PickSetState<models.CheckoutPaymentStepReactViewModel>) => void;
};


function typeOfHelpString(job: models.CheckoutPaymentStepJobViewModel) {
    const toh = job.typeOfHelp;

    if (toh.loadingHelp && toh.unloadingHelp) {
        return 'Load/Unload';
    } else if (toh.loadingHelp) {
        return 'Load';
    } else if (toh.unloadingHelp) {
        return 'Unload';
    } else if (toh.moveOnSiteOnly) {
        return 'Move On-Site';
    } else {
        return 'Moving Labor';
    }
}

const JobIcon = (job: models.CheckoutPaymentStepJobViewModel) => {
    const toh = job.typeOfHelp;

    if (toh.loadingHelp && toh.unloadingHelp) {
        return <Icon className="me-3 job-icon" fixedWidth icon={iconLibrary.faTruck} />;
    } else if (toh.loadingHelp) {
        return <Icon className="me-3 fa-solid job-icon" fixedWidth icon={iconLibrary.faDolly} />;
    } else if (toh.unloadingHelp) {
        return <Icon className="fa-flip-horizontal me-3 job-icon" fixedWidth icon={iconLibrary.faDolly} />;
    } else {
        return <Icon className="me-3 job-icon" fixedWidth icon={iconLibrary.faPersonDolly} />;
    }
};

export const ReservationDetails = ({ jobs, pageVm, payNow, handleChangeVm }: Props) => {
    const [show, onShow, onHide] = useShowHide(false);
    const isMultiOrder = jobs.length > 1;
    const chargeNotice = React.useMemo(() => {
        if (pageVm.anySameDay || pageVm.anyNextDay) {
            return 'Your card will be charged today to secure your reservation.';
        } else if (isMultiOrder) {
            return 'You will not be charged until the day before each move. You will be charged individually for the amount of each specific service.';
        } else {
            return 'You will not be charged until the day before the move.';
        }
    }, [pageVm.anySameDay, pageVm.anyNextDay, isMultiOrder]);

    /* TODO: This should pull from a TCPA markdown */
    const termsOverlay = (
        <Popover className="p-2">
            <div>
                <strong>Terms of Service</strong>
            </div>
            <div className="mt-2">We collect your billing info to secure your reservation. You will not be charged until the day before the move.</div>
            <div className="mt-2">Your service provider will not receive your payment until you tell us they completed your order via toll-free number or automated text.</div>
            <div className="mt-2">
                By clicking the &quot;Book now&quot; payment button, you agree to our{' '}
                <a href="https://www.porchmovinggroup.com/legal/" target="_blank" rel="noreferrer">
                    Terms of Service
                </a>
                .
            </div>
        </Popover>
    );

    return (
        <div className="mt-3">
            {show && MoveProtectionFeeModal(onHide)}
            <h4>Reservation Details</h4>
            <Card className="mt-2 shadow p-2 mb-4 bg-white rounded">
                <Card.Body>
                    {jobs.map((job) => (
                        <div className="mt-2" key={`checkout-job-reservation-${job.jobID}`}>
                            <Row>
                                <Col>
                                    <JobIcon {...job} />
                                    {typeOfHelpString(job)}, {job.numHelpers} crew x {job.hours} hrs
                                </Col>
                                <Col xs="auto" className="text-end">
                                    {formatCurrency(job.subtotalBeforeDiscount)}
                                </Col>
                            </Row>
                        </div>
                    ))}

                    <Row className="mt-2">
                        <Col onClick={onShow}>
                            <span>Move Protection Fee</span>
                            <Icon className="text-notification ms-1" fixedWidth icon={iconLibrary.faInfoCircle} />
                        </Col>
                        <Col xs="auto" className="text-end text-notification">
                            Included
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <div className="d-flex">
                                <div><Icon className="text-notification me-1 me-sm-2" fixedWidth icon={iconLibrary.faCheck} /></div>
                                <div>Best Price and Value Guarantee</div>
                            </div>
                        </Col>
                        <Col xs="auto" className="text-end text-notification">
                            Included
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <div className="d-flex">
                                <div><Icon className="text-notification me-1 me-sm-2" fixedWidth icon={iconLibrary.faCheck} /></div>
                                <div>Insured, Licensed and Vetted Professionals</div>
                            </div>
                        </Col>
                        <Col xs="auto" className="text-end text-notification">
                            Included
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <div className="d-flex">
                                <Icon className="text-notification me-1 me-sm-2" fixedWidth icon={iconLibrary.faCheck} />
                                <div><span className="d-md-none">Customer Service 365 Days/Year</span></div>
                                <div><span className="d-none d-md-inline">Customer Service 365 Days a Year</span></div>
                            </div>
                        </Col>
                        <Col xs="auto" className="text-end text-notification">
                            Included
                        </Col>
                    </Row>

                    {pageVm.allowToAddDiscount && payNow && (
                        <DiscountComponent title="Pay now and save" discountAmount={pageVm.discountAmount} discountFormatted={pageVm.payNowDiscountPriceFormatted} subtotal={pageVm.grandTotalFormatted} existingDiscountCode={pageVm.discountCode} authOrLinkedJobsKey={pageVm.authToken} handleChangeVm={handleChangeVm} />
                    )}


                    {pageVm.allowToAddDiscount && !payNow && (
                        <DiscountComponent title="Discount" discountAmount={pageVm.discountAmount} discountFormatted={pageVm.discountAmountFormatted} subtotal={pageVm.subtotalBeforeDiscountFormatted} existingDiscountCode={pageVm.discountCode} authOrLinkedJobsKey={pageVm.authToken} handleChangeVm={handleChangeVm} />
                    )}

                    <hr />
                    <Row className="mt-3">
                        <Col>Total</Col>
                        <Col xs="auto" className="text-end">
                            {payNow ? pageVm.payNowGrandTotalFormatted : pageVm.grandTotalFormatted}
                        </Col>
                    </Row>
                    <Row className="mt-1 mb-2">
                        <Col className="text-notification h5">Due now</Col>
                        <Col xs="auto" className="text-end text-notification h5">
                            {payNow ? pageVm.payNowGrandTotalFormatted : pageVm.balanceDueNowFormatted}
                        </Col>
                    </Row>
                    {!payNow && <span className="text-muted charge-notice">{chargeNotice}</span>}
                    <div className="text-muted mt-1">
                        Terms of Service
                        <OverlayTrigger trigger="click" rootClose placement="top" overlay={termsOverlay}>
                            <span className="ms-2 text-muted text-decoration-underline" role="button">
                                <Icon icon={iconLibrary.faInfoCircle} />
                            </span>
                        </OverlayTrigger>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

const MoveProtectionFeeModal = (onHideModal: () => void) => {
    const truckServiceImg = `${window.location.origin}/_static/svg/public/checkout/truck-service.svg`;

    return <Modal className="move-protection-fee-modal" show={true} onHide={onHideModal}>
        <Modal.Header closeButton />
        <Modal.Body>
            <div className="container-fluid">
                <div className="row text-center">
                    <div className="col-12">
                        <img src={truckServiceImg} width={226} height={166} alt="Move Protection Fee" />
                    </div>
                </div>
                <div className="row mt-2 text-center">
                    <div className="col-12">
                        <h4 className='fs-2'>Move Protection Fee</h4>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-12 fs-4">
                        <p>
                            We understand moving can be stressful, which is why our Move Protection Fee ensures you have access to customer service 365 days a year to call/text/chat with experienced agents, the lowest pricing available and fully vetted, insured, and licensed professional movers.
                            <br />
                            <br />
                            This provides you with peace of mind and reliable support every step of the way.
                        </p>
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button className="btn btn-notification-dark-10 rounded-pill fw-semibold fs-5 w-100" variant="light" onClick={onHideModal}>
                Great! I understand
            </Button>
        </Modal.Footer>
    </Modal>;
}
