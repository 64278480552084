import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { memo, useCallback } from 'react';
import Flatpickr from 'react-flatpickr';
import { CtaFormValues } from '../../modules/ctaFormSchema';

interface FieldProps {
	fieldName: keyof CtaFormValues;
	labelText?: string;
	labelClass?: string;
	fieldType: string;
	fieldValue: string;
	fieldClass: string;
	fieldIcon: IconProp;
	errorMessage?: string;
	unavailableDates?: string[];
    onFieldChange: (fieldName: keyof CtaFormValues, fieldValue: string) => void;
	minDate?: string | null;
}

export const HahDatePicker = memo((props: FieldProps) => {

    const minDatePicker = props.minDate ?? DateTime.now().toISODate();

	// Create handler for change event:
	const onFieldChange = useCallback(
		(value: string) => {
			props.onFieldChange(props.fieldName, value);
		},
		[props.onFieldChange, props.fieldName]
	);

    const fieldClassName = props.errorMessage ? 'border-error invalid ' + props.fieldClass : props.fieldClass;

	// Render all HTML components:
	return (
		<div className="form-floating inner-addon right-addon mb-1">
			<FontAwesomeIcon icon={ props.fieldIcon } className='form-field-icon'/>
            <Flatpickr
                className={fieldClassName}
                name={props.fieldName}
				id={props.fieldName}
                data-enable-time={false}
                value={props.fieldValue}
                options={{
                    inline: false,
                    minDate: minDatePicker!, //to prevent same or next day orders.
                    enableTime: false,
                    dateFormat: 'n/j/Y',
					allowInvalidPreload: true,
					disable: props.unavailableDates ?? [],
                }}
                onChange={(_, currentDate) => {
                    onFieldChange(currentDate);
                }} />
			<label htmlFor={props.fieldName} className={props.labelClass}>{props.labelText}</label>
            <p className='small text-error mb-0'>{props.errorMessage ?? '\u00A0'}</p>
		</div>
	);
});
