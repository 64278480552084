import { HahCheckbox } from '@hah/shared';
import { OnChangePickSetState } from '@hah/utils';
import { useFormikContext } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { HahFormikField } from './HahFormikField';

type Props = {
    pageVm: models.CheckoutPaymentStepReactViewModel;
    model: models.CheckoutPaymentViewModel;
    onChange: OnChangePickSetState<models.CheckoutPaymentViewModel>;
}
export const BillingContactInfo = ({ onChange, model, pageVm }: Props) => {
    const parentName = 'billingContact';
    const { setFieldValue } = useFormikContext();

    const handleChangeSameAsJobContact = React.useCallback((v: boolean) => {
        onChange({ billingContactSameAsJobContact: v });
        setFieldValue('billingContactSameAsJobContact', v);
    }, [onChange, setFieldValue]);

    return <div>

        <div className="mt-3">
            <h5>Billing Contact Info</h5>
            <HahCheckbox checked={model.billingContactSameAsJobContact} onCheckChange={handleChangeSameAsJobContact}>Same as move contact info</HahCheckbox>
        </div>
        {model.billingContactSameAsJobContact && <div className="mt-3 ps-4" dangerouslySetInnerHTML={{__html: pageVm.jobContactInfoHtmlReadonly}} />}
        {!model.billingContactSameAsJobContact && <div className="mt-3">
            <Row className="gx-3">
                <Col md={6} className="mb-3">
                    <HahFormikField name="firstName" parentName={parentName} label="First Name" autoComplete='billing cc-given-name' required />
                </Col>
                <Col md={6} className="mb-3">
                    <HahFormikField name="lastName" parentName={parentName} label="Last Name" autoComplete='billing cc-family-name' required />
                </Col>
                <Col md={12} className="mb-3">
                    <HahFormikField name="email" parentName={parentName} label="Email" autoComplete='billing email' required />
                </Col>
                <Col md={6} className="mb-3">
                    <HahFormikField name="primaryPhone" parentName={parentName} label="Primary Phone" autoComplete='billing tel' required />
                </Col>
                <Col md={6} className="mb-3">
                    <HahFormikField name="alternatePhone" parentName={parentName} label="Secondary Phone" />
                </Col>
            </Row>
        </div>}
    </div>
}
