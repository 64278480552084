import { formatCurrency } from '@hah/utils';
import { Col, Row } from 'react-bootstrap';

export function CancellationFeesList({ cancellationFees }: { cancellationFees: readonly models.CancellationFeeBreakpoint[]; }) {

    return (
        <div className="pt-3">
            <label className="fw-bold">Cancellation fees</label>
            {cancellationFees.map((x) => {
                return <Row key={`${x.label} Cancellation Policy`}>
                    <Col xs={6} className="pt-2">
                        {x.cancellationFee > 0 ? `${x.maxHours} ${x.minHours === 0 ? 'hour' : 'hours'} or less:` : `More than ${x.minHours} hours notice:`}
                    </Col>
                    <Col xs={6} className="text-end pt-2">
                        {x.cancellationFee > 0 ? formatCurrency(x.cancellationFee) : 'free to cancel'}
                    </Col>
                </Row>
            })}
        </div>
    );
}
