import * as Sentry from '@sentry/browser';
import { setHahContext } from '../custom-types/http-utils';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { HahDatePicker } from '../components/Forms/HahDatePicker';
import { iconLibrary, useOnMountUnsafe } from '@hah/shared';
import { JobDetailsAvailabilityResult } from '../custom-types/JobDetailsAvailabilityResult';
import ky from 'ky';
import { DateTime } from 'luxon';

// set hah version header and context
const hahVersion = import.meta.env.VITE_HAHVERSION ?? 'unknown';
setHahContext('hah-public-react-arrival-windows-index', hahVersion);
interface ArrivalWindowsProps {
    jobDate: string;
    arrivalWindow: string;
}

export const ArrivalWindows = (props: ArrivalWindowsProps) => {
    const params = useMemo(() => new URLSearchParams(window.location.search), []);

    const [moveDate, setMoveDate] = useState<string>('');
    const [fetching, setFetching] = useState<boolean>(false);
    const [arrivalWindows, setArrivalWindows] = useState<string[]>([]);
    const [showMore, setShowMore] = useState<boolean>(false);
    const [arrivalWindow, setArrivalWindow] = useState<string>(props.arrivalWindow);
    const [unavailableDates, setUnavailableDates] = useState<string[]>([]);
    const [posting, setPosting] = useState<boolean>(false);

    const logErrorToSentry = (error: Error) => {
        Sentry?.captureException(error, { tags: { params: window.location.search, 'grouping.Checkout': true } });
    };

    const lookupAvailabilityForDate = useCallback(
        async (date: string) => {
            if (fetching) {
                return;
            }
            setFetching(true);
            setMoveDate(date);
            params.append('jobPointer', '1');
            params.append('searchDate', date);

            try {
                const result = await ky
                    .get('/checkoutv2/jobdetailsavailability/', {
                        searchParams: params,
                        retry: 0, // by default Ky will retry 2 times, we don't want that
                        hooks: {
                            afterResponse: [
                                async (request, options, response) => {
                                    setFetching(false);
                                    setShowMore(false);
                                    return response;
                                },
                            ],
                        },
                    })
                    .json<JobDetailsAvailabilityResult>();

                setUnavailableDates(result.UnavailableDates);
                if (result.ArrivalWindowsList.length > 0) {
                    setArrivalWindows(result.ArrivalWindowsList);
                    const index = result.ArrivalWindowsList.indexOf(arrivalWindow);
                    if (arrivalWindow && index > 4) {
                        setShowMore(true);
                    }
                    if (!arrivalWindow) {
                        setArrivalWindow(result.ArrivalWindowsList[0]);
                    }
                } else {
                    setArrivalWindows([]);
                }
            } catch (error) {
                logErrorToSentry(error);
                globalSitewide.toastError(null, 'There was a problem retrieving provider availability for this date, please check the job date is valid and in the future or go back and start your search again.');
            }
            params.delete('jobPointer');
            params.delete('searchDate');
        },
        [arrivalWindow, fetching, params],
    );

    const handleSubmit = useCallback(
        async (evt: FormEvent<HTMLFormElement>) => {
            evt.preventDefault();
            if (!arrivalWindow || arrivalWindow.length === 0) {
                globalSitewide.toastError(null, 'Please select an arrival window window.');
                return;
            }
            if (!moveDate) {
                globalSitewide.toastError(null, 'Please select a valid move date.');
                return;
            }

            if (posting) {
                return;
            }
            setPosting(true);

            try {
                const result = await ky
                    .post('/checkoutv2/arrivalwindows/', {
                        searchParams: params,
                        body: new URLSearchParams({ arrivalWindow, loadingDate: moveDate }),
                        retry: 0,
                    })
                    .json<{ success: boolean; jobDetailsUrl: string }>();

                if (result.success) {
                    window.location.href = result.jobDetailsUrl;
                } else {
                    // This shouldn't happen, but if it does we need to know about it
                    logErrorToSentry(new Error('ArrivalWindows: user returned unsuccessful post response.'));
                    globalSitewide.toastError(null, 'There was a problem submitting your move, please try again or contact us for assistance.');
                }
            } catch (error) {
                logErrorToSentry(error);
                globalSitewide.toastError(null, 'There was a problem submitting your move, please try again or contact us for assistance.');
            }
            setPosting(false);
        },
        [arrivalWindow, moveDate, params, posting],
    );

    useOnMountUnsafe(() => {
        if (props.jobDate) {
            lookupAvailabilityForDate(props.jobDate);
        } else {
            logErrorToSentry(new Error('ArrivalWindows: no date in query string.'));
            window.location.href = window.location.href.replace('arrivalwindows', 'lfsordererrorcontactus');
        }
    });

    if (!moveDate || !arrivalWindows) {
        return <Spinner />;
    }

    return (
        <form id="interstitialForm" onSubmit={handleSubmit}>
            <input type="hidden" name="arrivalWindow" value={arrivalWindow} />
            <h3 className="mb-3 text-center">
                What time on <span className="text-notification">{moveDate}</span> should movers arrive?
            </h3>
            <HahDatePicker minDate={DateTime.now().plus({ days: 1 }).toISODate()} labelText="Date" fieldType="text" fieldName="loadingDate" fieldClass="form-control is-valid" fieldIcon={iconLibrary.faCalendar} fieldValue={moveDate} errorMessage={undefined} onFieldChange={(_, value) => { lookupAvailabilityForDate(value); }} unavailableDates={unavailableDates} />
            <p className="text-center text-neutral-grey-700" style={{ fontSize: 18 }}>
                Select a time below or change your move date to find more arrival windows
            </p>
            <div className="arrival-windows-container pb-3">
                {fetching ? (
                    <Spinner />
                ) : arrivalWindows && arrivalWindows.length === 0 ? (
                    <strong>No arrival windows available. Please select a different date.</strong>
                ) : (
                    <>
                        {arrivalWindows?.map((window, index) => {
                            // If we have more than 5 windows we just want to show the first 5 and a show more button, if the show more button is clicked we will show the rest of the windows
                            if (index > 4 && !showMore) {
                                return null;
                            }
                            return (
                                <button type="button" style={{ fontSize: 18, borderWidth: 1 }} className={`btn btn-outline-notification rounded-pill border-1 fw-semibold d-block w-100 shadow-sm mb-3 ${arrivalWindow === window ? 'active' : ''}`} key={index} onClick={() => setArrivalWindow(window)}>
                                    {window.replace('Arrival Between ', '')}
                                </button>
                            );
                        })}
                        {arrivalWindows && arrivalWindows.length > 5 && !showMore && (
                            <button type="button" style={{ fontSize: 18, borderWidth: 1 }} className="btn btn-outline-notification rounded-pill border-1 fw-semibold d-block w-100 shadow-sm mb-3" onClick={() => setShowMore(!showMore)}>
                                See More
                            </button>
                        )}
                    </>
                )}
            </div>
        </form>
    );
};

const Spinner = () => {
    return (
        <div className="lds-ring my-3">
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

