import { HelpTypeNeededEnum, InternalReferralSourceEnum, JobDetailsTypeOfHelpEnum, ProcessCtaDataCommandOutcome, TruckSizeRanges } from '@hah/enums';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { CtaContext } from '../Cta';
import { ProcessCtaDataCommandResult } from '../custom-types/ProcessCtaDataCommandResult';
import { CtaDataDto } from '../custom-types/cta-data-dto';
import { executePost } from '../custom-types/http-utils';
import { CtaFormErrors, CtaFormValues, ctaFormValidation } from '../modules/ctaFormSchema';
import { ExperimentIds } from '@hah/typewriter/constants';

const apiUrl = '/publicapi/process-cta-data/';

export interface SubmitCtaForm {
    ctaFormValues: CtaFormValues;
    errorHandler: (newErrors: any) => void;
}

function getCtaDataDto(
    irs: InternalReferralSourceEnum,
    helpTypeNeeded: HelpTypeNeededEnum,
    primaryJobDate: string | undefined,
    secondaryJobDate: string | undefined,
    loadingZip: string | null,
    unloadingZip: string | null,
    typeOfHelp: JobDetailsTypeOfHelpEnum,
    helperId: string | null,
    truckSizeRange: TruckSizeRanges | null,
): CtaDataDto {
    const outputModel = {} as CtaDataDto;

    outputModel.IRS = irs;
    outputModel.TypeOfHelp = typeOfHelp;
    outputModel.HelpTypeNeeded = helpTypeNeeded;

    if (helperId && helperId != null) {
        outputModel.HelperId = Number(helperId);
    }

    if (helpTypeNeeded === HelpTypeNeededEnum.MoversPlusTruck) {
        // only set fields for M+T
        outputModel.PrimaryJobDate = primaryJobDate;
        outputModel.LoadingZip = loadingZip?.trim();
        outputModel.UnloadingZip = unloadingZip?.trim();
        outputModel.TruckSizeRange = truckSizeRange;
    } else if (helpTypeNeeded === HelpTypeNeededEnum.LaborOnly) {
        // only set fields for labor-only
        if (typeOfHelp === JobDetailsTypeOfHelpEnum.Loading) {
            outputModel.LoadingZip = loadingZip?.trim();
            outputModel.PrimaryJobDate = primaryJobDate;
        } else if (typeOfHelp === JobDetailsTypeOfHelpEnum.Unloading) {
            outputModel.UnloadingZip = unloadingZip?.trim();
            outputModel.SecondaryJobDate = secondaryJobDate;
        } else if (typeOfHelp === JobDetailsTypeOfHelpEnum.LoadingAndUnloading) {
            outputModel.LoadingZip = loadingZip?.trim();
            outputModel.PrimaryJobDate = primaryJobDate;
            outputModel.UnloadingZip = unloadingZip?.trim();
            outputModel.SecondaryJobDate = secondaryJobDate;
        } else {
            HireahelperCsLogger.Main.sendDataWithMsg('[cta] Unhandled JobDetailsTypeOfHelpEnum: ' + typeOfHelp, undefined, 4);
            globalSitewide.toastErrorGenericMessage('There was an unexpected problem with your request.');
        }
    } else {
        HireahelperCsLogger.Main.sendDataWithMsg('[cta] Unhandled HelpTypeNeededEnum: ' + helpTypeNeeded, undefined, 4);
        globalSitewide.toastErrorGenericMessage('There was an unexpected problem with your request.');
    }

    return outputModel;
}

// !! duplicated in cta-forms.ts and HelperList.ts and useSubmitCtaForm.ts - please keep in sync !!
function isCtaOutcomeSuccess(outcome: ProcessCtaDataCommandOutcome): boolean {
    switch (outcome) {
        case ProcessCtaDataCommandOutcome.Success:
        case ProcessCtaDataCommandOutcome.SuccessCrossingStateLinesLfs:
        case ProcessCtaDataCommandOutcome.SuccessFallbackToSingleJobLaborOnlyDifferentStates:
        case ProcessCtaDataCommandOutcome.SuccessFallbackToSingleJobLaborOnlySameState:
        case ProcessCtaDataCommandOutcome.SuccessFallbackToTwoJobLaborOnly:
        case ProcessCtaDataCommandOutcome.SuccessLocalLfs:
        case ProcessCtaDataCommandOutcome.SuccessLongDistanceLfs:
            return true;
        default:
            return false;
    }
}

export default function useSubmitCtaForm({ ctaFormValues, errorHandler }: SubmitCtaForm) {
    const { ctaConfig } = React.useContext(CtaContext);

    const handleSubmit = React.useCallback(
        async (event: any) => {
            // Prevent form from submitting:
            event.preventDefault();

            // Check the schema if form is valid:
            const isFormValid = await ctaFormValidation.isValid(ctaFormValues, {
                abortEarly: true, // Prevent aborting validation after first error
            });

            if (isFormValid) {
                // Use irs if provided, else default to HomePage one for this A/B test.
                let irs = ctaConfig.irs;
                const searchParams = new URLSearchParams(window.location.search);
                const referralSource = searchParams.get('irs') as keyof typeof InternalReferralSourceEnum;

                if (referralSource && InternalReferralSourceEnum[referralSource] !== undefined) {
                    irs = InternalReferralSourceEnum[referralSource];
                }
                // at this point if we don't have an IRS, let's set it so we don't fail but log it so we see if it is happening often
                if (!irs) {
                    irs = InternalReferralSourceEnum.Unknown;

                    Sentry?.captureEvent({
                        message: 'RJS-CTA was missing irs',
                        level: 'warning',
                        tags: {
                            'rjs-component': 'ctaform',
                        },
                        extra: {
                            ctaFormValues,
                        },
                    });
                }

                const helpTypeNeeded = ctaFormValues.helpTypeNeeded!;

                let pricingClassificationIdGA = '1';
                if (helpTypeNeeded === HelpTypeNeededEnum.MoversPlusTruck) {
                    pricingClassificationIdGA = '3';
                }

                let ctaTypeGa = 'primary';
                if (irs === InternalReferralSourceEnum.HomepagePreFooterCta || irs === InternalReferralSourceEnum.PaidAdsMoveHelpFooter) {
                    ctaTypeGa = 'footer';
                }

                // If form is valid, continue submission.
                const ctaDataDto = getCtaDataDto(
                    irs,
                    helpTypeNeeded,
                    ctaFormValues.loadingDate,
                    ctaFormValues.unloadingDate,
                    ctaFormValues.loadingZip,
                    ctaFormValues.unloadingZip,
                    ctaFormValues.typeOfHelp,
                    null, // Helper ID doesn't even seem to be set on our normal forms?
                    ctaFormValues.truckSizeRange > 0 ? ctaFormValues.truckSizeRange : null,
                );

                try {
                    globalSitewide.blockPage();

                    // FUTURE: we would need to pass something in here to indicate what type of CTA form this is
                    HireahelperCsLogger.Main.sendEventToGoogleNewAndUs('cta_form_submit', ctaTypeGa, pricingClassificationIdGA, false); // dont log to us, just GA

                    // LaborOnly and checks for the AbTestingUtils file being present just in case
                    if (helpTypeNeeded === HelpTypeNeededEnum.LaborOnly && Hireahelper.Global.AbTestingUtils != undefined) {
                        const optimizely = window.optimizely;
                        await Hireahelper.Global.AbTestingUtils.bucketAndCheckForOptimizelyVariant(optimizely,
                            ExperimentIds.TestPMG4354_LOLocationInfoRetest_ApiName,
                            ExperimentIds.TestPMG4354_LOLocationInfoRetest_ID,
                            [ExperimentIds.TestPMG4491_LFSArrivalWindowsRetest_ID]);
                    }

                    const result = await executePost<ProcessCtaDataCommandResult>(apiUrl, ctaDataDto);

                    // if we're redirecting, then go and don't turn off the block
                    if (
                        (isCtaOutcomeSuccess(result.Outcome) ||
                            result.Outcome === ProcessCtaDataCommandOutcome.NoMoversPlusTruckAvail ||
                            result.Outcome === ProcessCtaDataCommandOutcome.ConsideredLongDistance ||
                            result.Outcome === ProcessCtaDataCommandOutcome.CrossingStateLines) &&
                        result.UrlToNavigateTo != null
                    ) {
                        window.location.href = result.UrlToNavigateTo;
                    } else {
                        globalSitewide.unblockPage();

                        const validationErrors: CtaFormErrors = {};
                        switch (result.Outcome) {
                            case ProcessCtaDataCommandOutcome.InvalidPrimaryDate:
                                globalSitewide.toastError('Oops...', 'Please enter a valid date (mm/dd/yyyy)');
                                validationErrors.loadingDate = 'Please enter a valid date (mm/dd/yyyy)';
                                break;
                            case ProcessCtaDataCommandOutcome.InvalidSecondaryDate:
                                globalSitewide.toastError('Oops...', 'Please enter a valid date (mm/dd/yyyy)');
                                validationErrors.unloadingDate = 'Please enter a valid date (mm/dd/yyyy)';
                                break;
                            case ProcessCtaDataCommandOutcome.SecondaryDateMustBeOnOrAfterPrimaryDate:
                                globalSitewide.toastError('Oops...', 'Your Unloading Date must occur on or after your Loading Date.');
                                validationErrors.unloadingDate = 'Your Unloading Date must occur on or after your Loading Date';
                                break;
                            case ProcessCtaDataCommandOutcome.InvalidLoadingZip:
                                globalSitewide.toastError('Oops...', 'Please enter a valid Loading ZIP code.');
                                validationErrors.loadingZip = 'Please enter a valid Loading ZIP code';
                                break;
                            case ProcessCtaDataCommandOutcome.InvalidUnloadingZip:
                                globalSitewide.toastError('Oops...', 'Please enter a valid Unloading ZIP code');
                                validationErrors.unloadingZip = 'Please enter a valid Unloading ZIP code';
                                break;
                            case ProcessCtaDataCommandOutcome.NotAvailableForSameDay:
                                globalSitewide.jQueryConfirmAlert(
                                    'Book Same Day',
                                    'Call our Moving Specialist Team to help book a same day Local Full Service Move.<br/><br/> <button class="btn btn-notification-dark-10 btn-lg btn-shadow rounded-pill fs-6 w-100" onclick="window.location.href=\'tel:+(866) 517-6369\'">Call (866) 517-6369 <span class="ps-1 far fa-arrow-right"></span></button><br/><br/><div class=\'text-center\'><button type=\'button\' class=\'btn btn-link\' onclick="jconfirm.instances[0].close();">Change Search</a></div>',
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    true,
                                    true,
                                );
                                validationErrors.truckSizeRange = 'Movers + Truck is not available for same-day booking.';
                                break;
                            default:
                                globalSitewide.toastError('Oops...', 'It appears there was an issue looking up service providers, please try again and if this problem persists contact support.');
                        }
                        errorHandler(validationErrors);
                    }
                } catch (ex) {
                    globalSitewide.unblockPage();

                    globalSitewide.toastError('Oops...', 'It appears there was an issue looking up service providers, please try again and if this problem persists contact support.');
                    const validationErrors: CtaFormErrors = {};
                    errorHandler(validationErrors);
                    Sentry?.captureException(ex, {
                        tags: {
                            'rjs-component': 'ctaform',
                        },
                        extra: {
                            apiUrl,
                            model: JSON.stringify(ctaDataDto),
                        },
                    });
                }
            } else {
                // If form is not valid, check which fields are incorrect:
                ctaFormValidation.validate(ctaFormValues, { abortEarly: false }).catch((err: any) => {
                    const validationErrors: CtaFormErrors = {};
                    let validationToastr = '';
                    err.inner.forEach((error: any) => {
                        validationErrors[error.path as keyof CtaFormValues] = error.message;
                    });

                    const entries = Object.entries(validationErrors).map((x) => x[1]);
                    validationToastr += entries.map((x) => x);
                    validationToastr = validationToastr.replaceAll(',', '</br>');

                    globalSitewide.toastError('Oops...', 'One or more errors occured while submitting your form: </br>' + validationToastr);
                    errorHandler(validationErrors);

                    Sentry?.captureEvent({
                        message: 'User attempted to submit the RJS CTA component with one or more validation errors',
                        level: 'log',
                        tags: {
                            'rjs-component': 'ctaform',
                        },
                        extra: {
                            ctaFormValues,
                            validationToastr,
                        },
                    });
                });
            }
        },
        [ctaConfig.irs, ctaFormValues, errorHandler],
    );

    return { handleSubmit };
}
