﻿



    

        
        
        export const TestPMG3851_CheckoutPayNow_ID = '29004720047';
    
        
        export const TestPMG3851_CheckoutPayNow_ApiName = 'checkout_pay_now_available';
    
        
        export const TestPMG3851_CheckoutPayNow_ControlID = '29033200058';
    
        
        export const TestPMG3851_CheckoutPayNow_VariantID = '28989080097';
    
        
        export const TestPMG4042_LFSRecommendationPage_ID = '28341070029';
    
        
        export const TestPMG4042_LFSRecommendationPage_ApiName = '25775630111_locationinfo';
    
        
        export const TestPMG4042_LFSRecommendationPage_ControlID = '28333830030';
    
        
        export const TestPMG4042_LFSRecommendationPage_VariantID = '28349610012';
    
        
        export const TestPMG4115_LFSArrivalWindows_ID = '29233430174';
    
        
        export const TestPMG4115_LFSArrivalWindows_ApiName = '25775630111_url_targeting_for_lfs_calendar_and_arrival_window';
    
        
        export const TestPMG4115_LFSArrivalWindows_ControlID = '29237720080';
    
        
        export const TestPMG4115_LFSArrivalWindows_VariantID = '29210250092';
    
        
        export const TestPMG4491_LFSArrivalWindowsFixed_ID = '29489300351';
    
        
        export const TestPMG4491_LFSArrivalWindowsFixed_ApiName = '25775630111_lfs_calendar_and_arrival_windows_fixed';
    
        
        export const TestPMG4491_LFSArrivalWindowsFixed_ControlID = '29467000700';
    
        
        export const TestPMG4491_LFSArrivalWindowsFixed_VariantID = '29542100015';
    
        
        export const TestPMG4354_LOLocationInfo_ID = '29332350837';
    
        
        export const TestPMG4354_LOLocationInfo_ApiName = '25775630111_lo_lfs_locationinfo_page';
    
        
        export const TestPMG4354_LOLocationInfo_ControlID = '29330770731';
    
        
        export const TestPMG4354_LOLocationInfo_VariantID = '29367060781';
    
        
        export const TestPMG4354_LOLocationInfoRetest_ID = '29625711346';
    
        
        export const TestPMG4354_LOLocationInfoRetest_ApiName = '25775630111_lo_location_info_retest';
    
        
        export const TestPMG4354_LOLocationInfoRetest_ControlID = '29596661494';
    
        
        export const TestPMG4354_LOLocationInfoRetest_VariantID = '29718290083';
    
        
        export const TestPMG4491_LFSArrivalWindowsRetest_ID = '29724180089';
    
        
        export const TestPMG4491_LFSArrivalWindowsRetest_ApiName = '25775630111_calendar_and_arrival_window_retest';
    
        
        export const TestPMG4491_LFSArrivalWindowsRetest_ControlID = '29627061296';
    
        
        export const TestPMG4491_LFSArrivalWindowsRetest_VariantID = '29737510109';
    
        
        export const MovingCoverageTest_ID = 'MovingCoverageTest';
    
        
        export const MovingCoverageTest_Control = 'MovingCoverageTest-Control';
    
        
        export const MovingCoverageTest_VariantA = 'MovingCoverageTest-VariantA';
    


