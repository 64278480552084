import { FaSymbol, FlipProp, IconDefinition, IconProp, PullProp, RotateProp, SizeProp, Transform } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as iconLibrary from '@hah/icons';
import * as React from 'react';
//export * as iconLibrary from './iconLibrary';

//import { CSSProperties } from 'react'

//library.add(iconLibrary);
export { iconLibrary };
export type { IconDefinition };

export const hahIcons = {
  helper: iconLibrary.faTruck,
  workerEmployee: iconLibrary.faPersonDolly,
  customer: iconLibrary.faUser,
  devUser: iconLibrary.faUserNinja,
  corporateCustomer: iconLibrary.faBuilding,
  partnerCustomer: iconLibrary.faPeopleArrows,
};

export const Icon: React.FC<Props> = FontAwesomeIcon;

// This is identical to the version of Omit in Typescript 3.5. It is included for compatibility with older versions of Typescript.
type BackwardCompatibleOmit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

export interface Props extends BackwardCompatibleOmit<React.SVGAttributes<SVGSVGElement>, 'children' | 'mask' | 'transform'>, React.RefAttributes<SVGSVGElement> {
  icon: IconProp
  mask?: IconProp
  maskId?: string
  className?: string
  color?: string
  spin?: boolean
  spinPulse?: boolean
  spinReverse?: boolean
  pulse?: boolean
  beat?: boolean
  fade?: boolean
  beatFade?: boolean
  bounce?: boolean
  shake?: boolean
  border?: boolean
  fixedWidth?: boolean
  inverse?: boolean
  listItem?: boolean
  flip?: FlipProp
  size?: SizeProp
  pull?: PullProp
  rotation?: RotateProp
  transform?: string | Transform
  symbol?: FaSymbol
  style?: React.CSSProperties
  tabIndex?: number;
  title?: string;
  titleId?: string;
  swapOpacity?: boolean;
}
