import * as React from 'react';
export type ActionLinkProps = Omit<React.HTMLProps<HTMLAnchorElement>, 'onClick'> & {
    stopPropagation?: boolean;

    onClick?: React.MouseEventHandler<HTMLAnchorElement>;

};

export function ActionLink(props: ActionLinkProps & {
    onClickAction?: () => void;
}): JSX.Element;
export function ActionLink<TClickArgs>(props: ActionLinkProps & {
    clickArgs: TClickArgs;
    onClickAction: (clickArgs: TClickArgs) => void;
}): JSX.Element;
export function ActionLink<TClickArgs>({ onClick, onClickAction, clickArgs, stopPropagation, ...rest }: ActionLinkProps & {
    clickArgs?: TClickArgs;
    onClickAction?: (clickArgs?: TClickArgs) => void;
}) {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {

        e.preventDefault();

        if (stopPropagation) {
            e.stopPropagation();
        }
        e.currentTarget.blur();

        if (onClickAction) {
            onClickAction(clickArgs);
        }
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <a href="#" {...rest} onClick={handleClick} />
    );
}
