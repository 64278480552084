import { getFieldValidationState, RequireAtLeastOne } from '@hah/utils';
import classNames from 'classnames';
import * as React from 'react';
import { Icon, iconLibrary } from '.';
import { HahFormContextData, HahFormDataContext } from './HahFormContext';

export interface HahInputProps<T, TValue = string> extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'name' | 'onChange' | 'value' | 'ref' | 'size'> {
    name: Extract<keyof T, string>;
    onChange?: (newValue: RequireAtLeastOne<T>) => void;
    parentName?: string;
    size?: 'sm' | 'lg';
    inputRef?: React.LegacyRef<HTMLInputElement>;
    value?: TValue;
}

export function HahInput<T, TValue = string>({ name, value, onChange, parentName, size, className, inputRef, ...rest }: HahInputProps<T, TValue>) {
    const handleChange = React.useCallback((evt: React.FormEvent<HTMLInputElement>) => {

        onChange && onChange({ [name]: evt.currentTarget.value } as unknown as RequireAtLeastOne<T, keyof T>);
    }, [name, onChange]);

    const { validationState, disabled } = React.useContext(HahFormDataContext) as HahFormContextData<T>;
    const vs = getFieldValidationState(validationState, name as string);
    return <input 
            className={classNames(getFormControlClassName(className, size), vs == 'success' && 'is-valid', vs == 'error' && 'is-invalid')} 
            ref={inputRef} 
            name={getInputFullName(name as string, parentName)} 
            value={(value || '') as string} 
            onChange={handleChange}
            disabled={disabled}
            {...rest} />;
}

export interface HahInputClearableProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    buttonClassName?: string;
    disabled?: boolean;
}

export function HahInputClearable({ value, onChange, onClear, placeholder, className, inputClassName, buttonClassName, disabled }: HahInputClearableProps) {
    return(
        <div className={classNames(className, 'd-flex align-items-center position-relative')}>
            <input 
                type="text" 
                placeholder={placeholder} 
                className={classNames(inputClassName, 'd-inline-flex pe-4 form-control')} 
                value={(value || '') as string} 
                onChange={onChange}
                disabled={disabled} />
            <button type="button" className={classNames(buttonClassName, 'btn btn-input')} onClick={onClear}><Icon className="text-muted" icon={iconLibrary.faTimes} /></button>
        </div>);
}

HahInput.defaultProps = {
    'autoComplete': 'nope',
    'data-lpignore': true,
    'className': 'form-control',
};

/** @internal */
export function getFormControlClassName(className: string | undefined, size: 'sm' | 'lg' | undefined) {
    if (!size) {
        return className;
    }
    //const size = SIZE_MAP[size] || size;
    return (className ? className + ' ' : '') + 'form-control-' + size;

}

/** @internal */
export function getInputFullName(name: string, parentName: string | undefined) {

    if (!parentName) {
        return name;
    }
    return parentName + '.' + name;
}
