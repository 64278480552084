import { CtaFormIds, InternalReferralSourceEnum } from '@hah/enums';
import { createContext } from 'react';
import { CtaForm } from './components/CtaForm';

interface IHireahelperJsSettings {
    sitePhoneNumber: string;
}

declare global {
    interface Window {
        HireahelperJsSettings: IHireahelperJsSettings;
    }
}

export const CtaContext = createContext({ ctaConfig: { irs: InternalReferralSourceEnum.Unknown } as models.ReactCtaConfig });

const getSchemaKey = (ctaFormId: CtaFormIds) => {
    switch (ctaFormId) {
        case CtaFormIds.FindMoversFooter:
            return 'cta-form-data-20230706-footer';
        default:
            return 'cta-form-data-20230706'; // this is so if we change storage schema between releases, it doesnt break clients local caches
    }
};

function CtaApp({ ctaConfig }: { ctaConfig: models.ReactCtaConfig }) {

    return (
        <div className="App">
            <CtaContext.Provider value={{ ctaConfig: ctaConfig }}>
                <CtaForm schemaKey={getSchemaKey(ctaConfig.formId)} />
            </CtaContext.Provider>
        </div>
    );
}

export default CtaApp;
