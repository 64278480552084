import { useCallback, useEffect, useRef, useState } from 'react';

export function useShowHide(initialValue = false): [boolean, () => void, () => void] {

    const [show, setShow] = useState(initialValue);

    const onShow = useCallback(() => {
        if (!unloaded.current) {
            setShow(true);
        }
    }, [setShow]);

    const onHide = useCallback(() => {

        if (!unloaded.current) {
            setShow(false);
        }
    }, [setShow]);


    const unloaded = useRef(false);
    useEffect(() => {
        unloaded.current = false;
        
        return () => {
            unloaded.current = true;
        }
    }, []);

    return [show, onShow, onHide];

}
