
import * as iconLibrary from '@hah/icons';
import { ActionButton, HahInput, Icon } from '@hah/shared';
import { PickSetState } from '@hah/utils';
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import * as api from '@hah/typewriter/controllers/CheckoutController';
import { Button, Col, Row } from 'react-bootstrap';

type DiscountComponentProps = {
    title: string;
    subtotal: string;
    discountAmount: number;
    discountFormatted: string;
    existingDiscountCode: string;
    authOrLinkedJobsKey: models.AuthOrLinkedJobsKey;
    handleChangeVm: (change: PickSetState<models.CheckoutPaymentStepReactViewModel>) => void;
}

export const DiscountComponent = ({ title, subtotal, discountFormatted, discountAmount, existingDiscountCode, authOrLinkedJobsKey, handleChangeVm }: DiscountComponentProps) => {
    const [showAddDiscountCode, setShowAddDiscountCode] = React.useState(existingDiscountCode && existingDiscountCode.length > 0);
    const [discountCodeApplied, setDiscountCodeApplied] = React.useState(existingDiscountCode);
    const [pendingDiscountCode, setPendingDiscountCode] = React.useState();
    const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
    const [discountCodeErrorMessage, setDiscountCodeErrorMessage] = React.useState<string>('');
    const toastExceptionMessage = 'An unexpected error has occurred. We have notified our team and do apologize for any inconvenience. If this continues to happen please call us and we would be happy to assist you.';

    const logErrorToSentry = (error: Error) => {
        Sentry?.captureException(error, { tags: {
            customerAuthKey: authOrLinkedJobsKey.customerAuthKey,
            linkedJobsKey: authOrLinkedJobsKey.linkedJobsKey,
            'grouping.Checkout': true
        } });
    };


    const handleApplyDiscount = async () => {
        if (isFormSubmitting || !pendingDiscountCode) {
            // we're already submitting. let's not cause double submits...
            return;
        }

        globalSitewide.blockPage();
        setIsFormSubmitting(true);
        setDiscountCodeErrorMessage('');

        const model = {
            discountCode: pendingDiscountCode,
            authOrLinkedJobsKey,
        } as models.CheckoutApplyDiscountViewModel;

        Sentry?.addBreadcrumb({
            category: 'discount-code',
            type: 'info',
            message: 'Attempting to apply discount code',
        });

        try {
            const response = await api.applyDiscountCode(model);

            if (!response.success) {
                // unsuccesful results will be logged in the api
                Sentry?.captureEvent({
                    level: 'warning',
                    tags: {
                        linkedJobsKey: authOrLinkedJobsKey.linkedJobsKey,
                        'grouping.Checkout': true,
                    },
                    message: 'User attempted to apply an invalid discount code.',
                    extra: {
                        model: JSON.stringify(model),
                        result: JSON.stringify(response),
                    },
                });

                // If we have an existing/applied discount, we should show that still
                setDiscountCodeErrorMessage(response.message);

                // if this fails and we return a redirectUrl we should redirect to the error page because there is a problem w/ their order
                if (response.result?.redirectUrl) {
                    window.location.href = response.result.redirectUrl;
                    return;
                }

                return;
            }

            setDiscountCodeApplied(pendingDiscountCode);
            setPendingDiscountCode(undefined);

            // Successful response - get new vm and update ui
            await fetchCheckoutPaymentViewModel();
        } catch (error: any) {
            globalSitewide.toastError(null, toastExceptionMessage, true);

            logErrorToSentry(error);
        }
        finally {
            globalSitewide.unblockPage();
            setIsFormSubmitting(false);
        }
    };

    const handleRemoveDiscount = async () => {
        const model = {
            discountCode: discountCodeApplied,
            authOrLinkedJobsKey,
        } as models.CheckoutApplyDiscountViewModel;

        globalSitewide.blockPage();
        setDiscountCodeErrorMessage('');

        Sentry?.addBreadcrumb({
            category: 'discount-code',
            type: 'info',
            message: 'Attempting to remove discount code',
        });

        try {
            const response = await api.removeDiscountCode(model);

            if (!response.success) {
                Sentry?.captureEvent({
                    level: 'warning',
                    tags: {
                        linkedJobsKey: authOrLinkedJobsKey.linkedJobsKey,
                        'grouping.Checkout': true,
                    },
                    message: 'User failed to remove a discount code.',
                    extra: {
                        model: JSON.stringify(model),
                        result: JSON.stringify(response),
                    },
                });

                // if this fails and we return a redirectUrl we should redirect to the error page because there is a problem w/ their order
                if (response.result?.redirectUrl) {
                    window.location.href = response.result.redirectUrl;
                    return;
                }

                return;
            }

            setDiscountCodeApplied('');

            // Successful response - get new vm and update ui
            await fetchCheckoutPaymentViewModel();
        }
        catch (error: any) {
            globalSitewide.toastError(null, toastExceptionMessage, true);

            logErrorToSentry(error);
        }
        finally {
            globalSitewide.unblockPage();
            setIsFormSubmitting(false);
        }
    };

    const fetchCheckoutPaymentViewModel = async () => {
        try {
            const getVm = await api.getCheckoutPaymentViewModel(authOrLinkedJobsKey);
            if (getVm.success) {
                handleChangeVm(getVm.result);
            }
            else {
                globalSitewide.toastError(null, toastExceptionMessage, true);
                Sentry?.captureEvent({
                    level: 'warning',
                    tags: {
                        linkedJobsKey: authOrLinkedJobsKey.linkedJobsKey,
                        'grouping.Checkout': true,
                    },
                    message: 'Failed to get new view model after adding/removing discount code.',
                    extra: {
                        result: JSON.stringify(getVm),
                    },
                });

                // if this fails and we return a redirectUrl we should redirect to the error page because there is a problem w/ their order
                if (getVm.result?.redirectUrl) {
                    window.location.href = getVm.result.redirectUrl;
                    return;
                }
            }
        }
        catch (error) {
            globalSitewide.toastError(null, toastExceptionMessage, true);
            logErrorToSentry(error);
        }
    }

    return <>
        {!showAddDiscountCode && <Row className="mt-3">
                <Col>
                    <Button id='pw-addDiscountCodeBtn' variant="outline-notification fw-semibold rounded" onClick={() => setShowAddDiscountCode(true)}>Add Discount Code</Button>
                </Col>
            </Row>
        }
        {showAddDiscountCode && <Row className="mt-3">
                <Col>
                    <HahInput
                        id="pw-pendingDiscountCode"
                        name="pendingDiscountCode"
                        className="form-control"
                        placeholder={`${discountCodeApplied && discountCodeApplied.length > 0 ? 'Discount Code Applied!': 'Enter discount code'}`}
                        value={pendingDiscountCode}
                        onChange={(e) => {
                                setDiscountCodeErrorMessage('');
                                setPendingDiscountCode(e.pendingDiscountCode);
                            }
                        }
                        />
                </Col>
                <Col xs="auto">
                    <ActionButton id='pw-applyDiscountBtn' disabled={!pendingDiscountCode || (pendingDiscountCode && discountCodeErrorMessage?.length > 0)} variant="secondary" onClickAction={handleApplyDiscount}>Apply Code</ActionButton>
                </Col>
            </Row>
        }
        {discountCodeApplied && discountCodeApplied.length > 0 && <Row className="mt-2">
                <Col>
                    <Icon className="ms-2 me-1 text-primary" icon={iconLibrary.faBolt} /> {discountCodeApplied}
                </Col>
                <Col xs="auto">
                <ActionButton id='pw-removeDiscountBtn' variant="link text-base-dark-10 p-0 fw-normal" onClickAction={handleRemoveDiscount}><Icon className="me-2" icon={iconLibrary.farTimes} /></ActionButton>
                </Col>
            </Row>
        }
        {discountCodeErrorMessage?.length > 0 && <Row className="mt-2">
                <Col className='text-error'>
                    <Icon className="ms-2 me-1" icon={iconLibrary.farExclamationCircle} /> {discountCodeErrorMessage}
                </Col>
            </Row>
        }

        <Row className="mt-3">
            <Col>Subtotal</Col>
            <Col xs="auto" className="text-end">
                {subtotal}
            </Col>
        </Row>
        {discountCodeApplied && discountAmount > 0 && <Row id='pw-discountRow' className="mt-1">
            <Col className="text-primary-dark-10">{title}</Col>
            <Col xs="auto" className="text-primary-dark-10 text-end">
                -{discountFormatted}
            </Col>
        </Row>
        }
    </>
};
