import { RequireAtLeastOne } from '@hah/utils';
import classNames from 'classnames';
import * as React from 'react';
import { getInputFullName } from './HahInput';

export interface HahLegacyCheckboxProps<T> extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'type' | 'name' | 'onChange' | 'value' | 'ref'> {
    name: keyof T;
    onChange?: (newValue: RequireAtLeastOne<T>) => void;
    parentName?: string;
    checked: boolean | undefined;
}

export class HahLegacyCheckbox<T> extends React.PureComponent<HahLegacyCheckboxProps<T>> {

    static defaultProps = {
        className: 'form-check',
    };
    handleChange = (evt: React.FormEvent<HTMLInputElement>) => {

        const { onChange, name } = this.props;
        onChange && onChange({ [name]: evt.currentTarget.checked } as unknown as RequireAtLeastOne<T>);
    }
    render() {
        const { name, checked, onChange, parentName, className, children,  disabled, ...rest } = this.props;

        return <div {...rest} className={className}>
            <label className="form-check-label">
                <input className="form-check-input me-2" type="checkbox" name={getInputFullName(name as string, parentName)} checked={checked || false} onChange={this.handleChange} disabled={disabled} />
                {children}
            </label>
        </div>;
    }

}


export type HahCheckboxProps = {
    //onChange?: <TKey extends keyof T>(newValue: Pick<T, TKey>) => void;
    //parentName?: string;
    checked: boolean | undefined;
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    onCheckChange?: (checked: boolean) => void;
    inline?: boolean;

    title?: string;
    className?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    name?: string;
    type?: 'checkbox' | 'radio';
    value?: string | number;
    'data-testid'?: string | number;
}
    & ({
        label: string;
    }
        | {
            children: React.ReactNode;
        }
    )

export const HahCheckbox = ({ title, type = 'checkbox', onChange, onCheckChange, className, style, disabled, inline, checked, value, 'data-testid': testid, ...props }: HahCheckboxProps) => {


    const { label, children, name, ...rest } = props as HahCheckboxProps & { label: string; children: React.ReactNode };

    const handleChange = React.useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {

        onChange?.(evt);

        onCheckChange?.(evt.target.checked);
    }, [onChange, onCheckChange]);

    return <div {...rest} className={classNames('form-check', inline ? 'form-check-inline' : undefined, className)} style={style}>
        <label className="form-check-label">
            <input className="form-check-input" name={name} type={type} checked={checked || false} onChange={handleChange} disabled={disabled} value={value} title={title} data-testid={testid} />
            {children ?? label}
        </label>
    </div>;
}
