import { RequireAtLeastOne, ValidationStateMap } from '@hah/utils';
import * as React from 'react';

export interface HahFormContextData<T = {}> {
    model?: T;
    validationState?: ValidationStateMap<T>;

    onChange?: (newModel: RequireAtLeastOne<T>) => void;
    disabled?: boolean;
    parentName?: string;
}
export const HahFormDataContext = React.createContext({} as HahFormContextData);

export const HahFormContext: React.FC<React.PropsWithChildren<HahFormContextData>> = ({ model, validationState, parentName, disabled, onChange, children }) => {

    const state = React.useMemo(() => ({ model, validationState, parentName, disabled, onChange }), [model, validationState, parentName, disabled, onChange]);

    return <HahFormDataContext.Provider value={state}>
        {children}
    </HahFormDataContext.Provider>;
};
