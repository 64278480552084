import { Card, Col, Row } from 'react-bootstrap';

export const PorchHomeAssistant = () => {
    return <Card className='mt-2 shadow p-2 mb-4 bg-white rounded'>
        <Card.Body>
            <Row>
                <Col>
                    <h5 className='mt-3'>Porch Home Assistant</h5>
                </Col>
                <Col xs="auto">
                    <img width={120} className="width: 120px;" src="/_static/img/public/checkout/porch-home-assistant-gold.png" />
                </Col>
            </Row>
            <div className='mt-2'>
                HireAHelper partners with Porch to provide Home Assistant Gold to you at no extra cost! 
                Think of the Home Assistant as a 24/7 personal support team for your home. 
                Following your booked reservation, you’ll be contacted by your Porch Home Assistant.
            </div>
        </Card.Body>
    </Card>;
}