import { CtaFormIds, CtaFormIdsDescription } from '@hah/enums';
import React from 'react';
import ReactDOM from 'react-dom/client';
import CtaApp from './Cta';
import { CheckoutApp } from './checkout';
import { setHahContext } from './custom-types/http-utils';
import { JobDetailsAddressAutoComplete } from './jobdetails';
import { ArrivalWindows } from './arrival-windows';

declare global {
    interface Window {
        PrimaryJob_PrimaryJobAddress: models.JobDetailsAddressViewModel;
        PrimaryJob_SecondaryJobAddress: models.JobDetailsAddressViewModel;
        SecondaryJob_PrimaryJobAddress: models.JobDetailsAddressViewModel;
        reactLoaded: boolean;
    }
}
// VITE uses .env for env vars.
// need to add .env.local to app and set VITE_HAHVERSION="0.0.1"
const hahVersion = import.meta.env.VITE_HAHVERSION ?? 'unknown';

setHahContext('hah-public-react', hahVersion);

// we do NOT need to init sentry here, since it's being initialized in the container web app. see PMG-2358

const getCtaConfigFromElementData = (element: HTMLElement): models.ReactCtaConfig => {
    return JSON.parse(element.dataset.ctaconfigjson!) as models.ReactCtaConfig;
};

document.addEventListener('DOMContentLoaded', () => {
    const homepageCta = document.getElementById(CtaFormIdsDescription.get(CtaFormIds.Homepage)!) as HTMLElement;
    if (homepageCta) {
        ReactDOM.createRoot(homepageCta).render(
            <React.StrictMode>
                <CtaApp ctaConfig={getCtaConfigFromElementData(homepageCta)} />
            </React.StrictMode>,
        );
    }

    const cityCta = document.getElementById(CtaFormIdsDescription.get(CtaFormIds.CityOrRegion)!) as HTMLElement;
    if (cityCta) {
        ReactDOM.createRoot(cityCta).render(
            <React.StrictMode>
                <CtaApp ctaConfig={getCtaConfigFromElementData(cityCta)} />
            </React.StrictMode>,
        );
    }

    const findMoversFooterCta = document.getElementById(CtaFormIdsDescription.get(CtaFormIds.FindMoversFooter)!) as HTMLElement;
    if (findMoversFooterCta) {
        ReactDOM.createRoot(findMoversFooterCta).render(
            <React.StrictMode>
                <CtaApp ctaConfig={getCtaConfigFromElementData(findMoversFooterCta)} />
            </React.StrictMode>,
        );
    }

    const jobDetailsAddressEl = document.querySelectorAll('.autocomplete-react-container');
    if (jobDetailsAddressEl) {
        jobDetailsAddressEl.forEach((el) => {
            const isPrimaryAddress = Boolean(el.classList.contains('PrimaryAddress'));
            const isPrimaryJob = Boolean(el.classList.contains('PrimaryJob'));
            const jobAddress = isPrimaryJob ? (isPrimaryAddress ? window.PrimaryJob_PrimaryJobAddress : window.PrimaryJob_SecondaryJobAddress) : window.SecondaryJob_PrimaryJobAddress;
            ReactDOM.createRoot(el).render(
                <React.StrictMode>
                    <JobDetailsAddressAutoComplete isPrimaryJob={isPrimaryJob} isPrimaryAddress={isPrimaryAddress} jobAddress={jobAddress} />
                </React.StrictMode>,
            );
        });
    }
const arrivalWindowsEl = document.getElementById('arrival-windows-react-container') as HTMLElement;
  if (arrivalWindowsEl) {
    const jobDate: string = arrivalWindowsEl.dataset.date!;
    const arrivalWindow: string = arrivalWindowsEl.dataset.arrivalwindow!;
    ReactDOM.createRoot(arrivalWindowsEl).render(
      <React.StrictMode>
        <ArrivalWindows jobDate={jobDate} arrivalWindow={arrivalWindow} />
      </React.StrictMode>,
    )
  }
});

const reactOptions = {
    options: undefined,
};

Object.defineProperty(window, 'reactOptions', {
    get() {
        return reactOptions;
    },

    set(options) {
        reactOptions.options = options;

        const checkoutElement = document.getElementById('checkout-react-container') as HTMLElement;
        if (checkoutElement) {
            ReactDOM.createRoot(checkoutElement).render(
                <React.StrictMode>
                    <CheckoutApp {...options} />
                </React.StrictMode>,
            );
        }
    },
});
