import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, useCallback, ChangeEvent } from 'react';
import { CtaFormValues } from '../../modules/ctaFormSchema';

interface FieldProps {
	fieldName: keyof CtaFormValues;
	labelText?: string;
	labelClass?: string;
	fieldType: string;
	fieldValue: string;
	fieldClass: string;
	fieldIcon: IconDefinition;
	fieldIconClass?: string;
	errorMessage?: string;
    autoComplete?: string;
    onFieldChange: (fieldName: keyof CtaFormValues, fieldValue: string) => void;
}

export const HahInput = memo((props: FieldProps) => {
	// Create handler for change event:
	const onFieldChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			props.onFieldChange(props.fieldName, event.target.value);
		},
		[props.onFieldChange, props.fieldName]
	);
    const fieldClassName = props.errorMessage ? 'border-error invalid ' + props.fieldClass : props.fieldClass;
	// Render all HTML components:
	return (
		<div className="form-floating inner-addon right-addon mb-1">
			<FontAwesomeIcon icon={ props.fieldIcon } className={'form-field-icon ' + props.fieldIconClass}/>
			<input
				type={props.fieldType}
				className={fieldClassName}
				name={props.fieldName}
				id={props.fieldName}
				value={props.fieldValue}
				placeholder="placeholder"
				onChange={onFieldChange}
                autoComplete={props.autoComplete}
			/>
			<label htmlFor={props.fieldName} className={props.labelClass}>{props.labelText}</label>
			<p className='small text-error mb-0'>{props.errorMessage ?? '\u00A0'}</p>
		</div>
	);
});
