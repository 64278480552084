import { HelpTypeNeededEnum, JobDetailsTypeOfHelpEnum } from '@hah/enums';
import * as yup from 'yup';

export interface CtaFormValues extends yup.InferType<typeof ctaFormValidation> {
    typeOfHelp: JobDetailsTypeOfHelpEnum;
    helpTypeNeeded?: HelpTypeNeededEnum | undefined;
    loadingZip: string;
    loadingDate: string;
    unloadingZip: string;
    unloadingDate: string;
    truckSizeRange: number;
}

// This creates the interface dynamically based on CtaFormValues.
export type CtaFormErrors = Partial<Record<keyof CtaFormValues, string>>;

export const ctaFormValidation = yup.object().shape({
    typeOfHelp: yup.number().required().min(JobDetailsTypeOfHelpEnum.Loading).max(JobDetailsTypeOfHelpEnum.MoveOnSiteOnly),
    loadingZip: yup.string().when('typeOfHelp', {
        is: (val: JobDetailsTypeOfHelpEnum) => val === JobDetailsTypeOfHelpEnum.Loading || val === JobDetailsTypeOfHelpEnum.LoadingAndUnloading,
        then: (schema) =>
            schema
                .matches(/^[0-9]+$/, 'ZIP code must be only digits')
                .min(5, 'Loading ZIP code must be exactly 5 digits')
                .max(5, 'Loading ZIP code must be exactly 5 digits')
                .required('Please enter a valid loading ZIP code'),
        otherwise: (schema) => schema.notRequired(),
    }),
    loadingDate: yup
        .string()
        .nullable()
        .when('typeOfHelp', {
            is: (val: JobDetailsTypeOfHelpEnum) => val === JobDetailsTypeOfHelpEnum.Loading || val === JobDetailsTypeOfHelpEnum.LoadingAndUnloading,
            then: (schema) => schema.required('Please select a loading date'),
            otherwise: (schema) => schema.notRequired(),
        }),
    unloadingZip: yup.string().when('typeOfHelp', {
        is: (val: JobDetailsTypeOfHelpEnum) => val === JobDetailsTypeOfHelpEnum.Unloading || val === JobDetailsTypeOfHelpEnum.LoadingAndUnloading,
        then: (schema) =>
            schema
                .matches(/^[0-9]+$/, 'ZIP code must be only digits')
                .min(5, 'Unloading ZIP code must be exactly 5 digits')
                .max(5, 'Unloading ZIP code must be exactly 5 digits')
                .required('Please enter a valid unloading ZIP code'),
        otherwise: (schema) => schema.notRequired(),
    }),
    unloadingDate: yup
        .string()
        .nullable()
        .when('typeOfHelp', {
            is: (val: JobDetailsTypeOfHelpEnum) => val === JobDetailsTypeOfHelpEnum.Unloading || val === JobDetailsTypeOfHelpEnum.LoadingAndUnloading,
            then: (schema) => schema.required('Please select an unloading date'),
            otherwise: (schema) => schema.notRequired(),
        })
        .test('greater-than-or-equal', 'Unloading date must be greater than or equal to loading date', function (value) {
            const loadingDateValue = this.parent.loadingDate;
            const typeOfHelp: JobDetailsTypeOfHelpEnum = this.parent.typeOfHelp;
            const helpTypeNeeded: HelpTypeNeededEnum = this.parent.helpTypeNeeded;
            if (!loadingDateValue || !value || typeOfHelp !== JobDetailsTypeOfHelpEnum.LoadingAndUnloading || helpTypeNeeded === HelpTypeNeededEnum.MoversPlusTruck) {
                return true;
            }

            const loadDate = new Date(loadingDateValue);
            const unloadDate = new Date(value);

            if (loadDate.toString() === 'Invalid Date' || unloadDate.toString() === 'Invalid Date') {
                return true;
            }

            return loadDate <= unloadDate;
        }),
    truckSize: yup.string().notRequired(),
});
