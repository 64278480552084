import { ServiceType } from '@hah/enums';
import * as iconLibrary from '@hah/icons';
import { ActionLink, CancellationFeesList, DateDisplay, Icon, StarDisplay, formatAddressOneLine, useShowHide } from '@hah/shared';
import { OnChangePickSetState, formatCurrency, formatNumber } from '@hah/utils';
import { DateTime } from 'luxon';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

type JobListProps = {
    jobs: readonly models.CheckoutPaymentStepJobViewModel[];
    jobDetailsUrl: string;
    model: models.CheckoutPaymentViewModel;
    onChange: OnChangePickSetState<models.CheckoutPaymentViewModel>;
}

function jobHeaderString(job: models.CheckoutPaymentStepJobViewModel, index: number, totalJobs: number) {
    const toh = job.typeOfHelp;
    let result = '';
    if (job.pricingClassificationID == ServiceType.MoversPlusTruck) {
        result += 'Mover + Truck';
    }
    else if (toh.loadingHelp && toh.unloadingHelp) {
        result += 'Loading & Unloading Services';
    }
    else if (toh.loadingHelp) {
        result += 'Loading Service'
    }
    else if (toh.unloadingHelp) {
        result += 'Unloading Service'
    }
    else if (toh.moveOnSiteOnly) {
        result += 'Move On-Site';
    }
    else {
        result += 'Moving Labor'
    }

    if (totalJobs > 1) {
        result += ` (${index + 1}/${totalJobs} orders)`;
    }
    return result;
}
function jobHeaderStringMobile(job: models.CheckoutPaymentStepJobViewModel, index: number, totalJobs: number) {
    const toh = job.typeOfHelp;
    let result = '';
    if (job.pricingClassificationID == ServiceType.MoversPlusTruck) {
        result += 'Mover + Truck';
    }
    else if (toh.loadingHelp && toh.unloadingHelp) {
        result += 'Load & Unload Services';
    }
    else if (toh.loadingHelp) {
        result += 'Load Service'
    }
    else if (toh.unloadingHelp) {
        result += 'Unload Service'
    }
    else if (toh.moveOnSiteOnly) {
        result += 'Move On-Site';
    }
    else {
        result += 'Moving Labor'
    }

    if (totalJobs > 1) {
        result += ` (${index + 1}/${totalJobs})`;
    }
    return result;
}

export const JobList = ({ jobs, jobDetailsUrl, ...rest }: JobListProps) => {
    return <>
        {jobs.map((job, i) => <div className='mt-2' key={`checkout-job-${job.jobID}`}>
            <Row>
                <Col>
                    <h4 className='d-none d-lg-block'>{jobHeaderString(job, i, jobs.length)}</h4>
                    <h4 className='d-block d-lg-none'>{jobHeaderStringMobile(job, i, jobs.length)}</h4>
                </Col>
                {jobDetailsUrl && <Col xs="auto" className="text-end">
                    <a className='text-decoration-none' href={jobDetailsUrl}><Icon icon={iconLibrary.faEdit} /> Edit Details</a>
                </Col>}
            </Row>
            <JobDetails job={job} {...rest} />
        </div>)}
    </>
}

type JobDetailsProps = {
    job: models.CheckoutPaymentStepJobViewModel;
    model: models.CheckoutPaymentViewModel;
    onChange: OnChangePickSetState<models.CheckoutPaymentViewModel>;
}

const JobDetails = ({ job, model, onChange }: JobDetailsProps) => {
    const [show, onShow, onHide] = useShowHide(false);
    const [showJobNotes, onShowJobNotes] = useShowHide(false);
    const [notes, setNotes] = React.useState(job.jobNotes);

    const showHelperDetails = React.useCallback((section = 'overview') => {
        helperDetailsModal.OpenProfile(job.helperID, job.workerLocationID, job.pricingClassificationID, section);
    }, [job.helperID, job.workerLocationID, job.pricingClassificationID]);

    const handleChangeJobNotes = React.useCallback((jNotes: readonly models.CheckoutJobNotesViewModel[] = [], note: string) => {
        const jobNotes = [...jNotes.filter(x => x.jobID != job.jobID), { jobID: job.jobID, notes: note.trim() }];
        onChange({ jobNotes });
    }, [onChange, job.jobID]);

    return <div className='mt-2'>

        <Card className="shadow p-2 mb-4 bg-white rounded">
            <Card.Body>
                <Row>
                    {job.helperImageUrl && <Col xs="auto">
                        <img src={job.helperImageUrl} alt={job.helperCompanyName} width="55" />
                    </Col>}
                    <Col>
                        <ActionLink onClickAction={showHelperDetails}><h5>{job.helperCompanyName}</h5></ActionLink>
                        {(job.helperRating ?? 0) > 0 && <Row>
                            <Col xs="auto" className='small pe-0 text-primary'>
                                <StarDisplay rating={job.helperRating!} id={0} />
                            </Col>
                            <Col xs="auto" className='pe-0 ps-2'>
                                {formatNumber(job.helperRating!, { decimalPlaces: 1 })}
                            </Col>
                            <Col xs={12} md="auto">
                                <ActionLink className='text-decoration-none' onClickAction={showHelperDetails} clickArgs={'reviews'}>{job.helperReviewCount} verified reviews</ActionLink>
                            </Col>
                        </Row>}
                    </Col>
                </Row>
                <hr />
                <div className='ps-2 mb-3'>
                    <div className="mt-3 row">
                        <Icon className='text-notification-dark-10 col-auto pe-0 pt-1' fixedWidth icon={iconLibrary.faClock} />
                        <div className='col'>
                            {job.preferredArrivalWindow}
                        </div>
                    </div>
                    <div className="mt-3 row">
                        <Icon className='text-notification-dark-10 col-auto pe-0 pt-1' fixedWidth icon={iconLibrary.faCalendar} />
                        <div className='col'>
                            <DateDisplay value={job.jobDate} format={DateTime.DATE_HUGE} />
                        </div>
                    </div>
                    <div className="mt-3 row">
                        <Icon className='text-notification-dark-10 col-auto pe-0 pt-1' fixedWidth icon={job.jobAddress2 ? iconLibrary.faLocation : iconLibrary.faLocationDot} />
                        <div className='col'>
                            {formatAddressOneLine(job.jobAddress)}
                        </div>
                    </div>
                    {job.jobAddress2 && <div className="mt-3 position-relative row">
                        <Icon className='text-notification-dark-10 fa-sharp position-absolute mt-n3' fixedWidth icon={iconLibrary.faEllipsisV} />
                        <Icon className='text-notification-dark-10 col-auto pe-0 pt-1' fixedWidth icon={iconLibrary.faLocationDot} />
                        <div className='col'>
                            {formatAddressOneLine(job.jobAddress2)}
                        </div>
                    </div>}
                </div>
                <ActionLink className='text-decoration-none text-dark fw-bold' onClick={() => show ? onHide() : onShow()}>Details and Fees <Icon icon={show ? iconLibrary.faChevronUp : iconLibrary.faChevronDown} /></ActionLink>
                {show && <div className='ps-2'>
                    {job.transportOptions.map((x, i) => <div key={`job-${job.jobID}-transport-${i}`} className="mt-3 text-weight-bold row">
                        <Icon className='text-notification-dark-10 col-auto pe-0 pt-1' icon={iconLibrary.faTruck} />
                        <div className='col'>
                            {job.pricingClassificationID == ServiceType.MoversPlusTruck ? <span>{x}</span> : <span>You Provide {x}</span>}
                        </div>
                    </div>)}
                    {job.whatsIncluded.travelFees && job.whatsIncluded.travelFees.length > 0 && <div className="mt-3 row">
                        <Icon className='col-auto pe-0 pt-1' icon={iconLibrary.faTruckClock} />
                        <div className='col'>
                            Additional travel: {job.whatsIncluded.travelFees.join(' and ')}
                        </div>
                    </div>}
                    <div className="mt-3 row">
                        <Icon className='col-auto pe-0 pt-1' icon={iconLibrary.faUserClock} />
                        <div className='col'>
                            Additional hours: {formatCurrency(job.hourlyRate)}/hr (past reservation)
                        </div>
                    </div>

                    <Row>
                        <Col md={6} className="mt-3">
                            <label className="fw-bold">Price Includes</label>
                            <div className="mt-2">
                                All travel fees
                            </div>
                            {job.whatsIncluded.additionalItemsHtml.map((item, index) => <div key={`whats-included-${index}`} className="mt-2" dangerouslySetInnerHTML={{ __html: item }} />)}
                        </Col>
                        <Col md={6} className="mt-3">
                            <label className="fw-bold">Heavy Items</label>
                            {(job.heavyItems?.length ?? 0) > 0 ? job.heavyItems.map((item, index) => <div key={`heavy-item-${index}`} className="mt-2">{item}</div>) :
                                <div className="mt-2">
                                    No heavy items
                                </div>}
                        </Col>
                    </Row>
                    <CancellationFeesList cancellationFees={job.cancellationFees} />
                    <div className="mt-3">
                        {!showJobNotes && <div className='text-end'>
                            <ActionLink className='text-decoration-none' onClick={() => onShowJobNotes()}><Icon icon={iconLibrary.faEdit} /> {job.jobNotes && job.jobNotes.length > 0 ? 'Edit' : 'Add'} Moving Notes</ActionLink>
                            {job.jobNotes && job.jobNotes.length > 0 && <textarea readOnly disabled className='form-control mt-2' value={notes} />}
                        </div>}
                        {showJobNotes && <div>
                            <strong>Moving Notes</strong>
                            <textarea className='form-control mt-2' value={notes} onChange={v => setNotes(v.currentTarget.value)} onBlur={() => handleChangeJobNotes(model.jobNotes, notes)} />
                            <div className="mt-2 small text-muted">
                                <strong>Privacy for moving notes:</strong> Notes are only visible to your moving provider and HireAHelper staff so we can ensure you receive the best service possible.
                            </div>
                        </div>}
                    </div>
                </div>}

            </Card.Body >
        </Card >
    </div>
}
