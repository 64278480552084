import * as React from 'react';
import { Button, ButtonProps, OverlayTrigger, Tooltip } from 'react-bootstrap';

export type ActionButtonProps = React.PropsWithChildren<Omit<ButtonProps & React.HTMLAttributes<HTMLButtonElement>, 'onClick'> & {
    stopPropagation?: boolean;

    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabledTooltip?: string;
}>;


export function ActionButton(props: ActionButtonProps & {
    onClickAction?: () => void;
}): JSX.Element;
export function ActionButton<TClickArgs>(props: ActionButtonProps & {
    clickArgs: TClickArgs;
    onClickAction: (clickArgs: TClickArgs) => void;
}): JSX.Element;
export function ActionButton<TClickArgs>({ onClick, onClickAction, clickArgs, stopPropagation, disabled, disabledTooltip, ...rest }: ActionButtonProps & {
    clickArgs?: TClickArgs;
    onClickAction?: (clickArgs?: TClickArgs) => void;
}) {
    const handleClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {

        e.preventDefault();

        if (stopPropagation) {
            e.stopPropagation();
        }
        e.currentTarget.blur();

        if (onClickAction) {
            onClickAction(clickArgs);
        }
        if (onClick) {
            onClick(e);
        }
    }, [stopPropagation, onClick, onClickAction, clickArgs]);

    if (disabled && disabledTooltip) {
        const tooltip = <Tooltip id="disabled-tooltip">{disabledTooltip}</Tooltip>;

        return <OverlayTrigger placement="top" overlay={tooltip}><span style={{ display: 'inline-block' }}><Button {...rest} disabled={disabled} /></span></OverlayTrigger>;
    }

    return (
        <Button {...rest} disabled={disabled} onClick={handleClick} />
    );
}
