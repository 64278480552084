﻿import * as enums from '@hah/enums';





import { executePost } from '../http-utils';




    export const endpointUrl = '/hahpi/public/checkout/';
    


    
    export const checkout = (model: models.CheckoutPaymentViewModel) => executePost<models.HahApiResult<models.CheckoutResultViewModel>>(endpointUrl + 'checkout/', model);
    
    export const getBtClientToken = () => executePost<models.BrainTreeTokenResult>(endpointUrl + 'getBtClientToken/');
    
    export const applyDiscountCode = (model: models.CheckoutApplyDiscountViewModel) => executePost<models.HahApiResult<models.CheckoutResultViewModel>>(endpointUrl + 'applyDiscountCode/', model);
    
    export const removeDiscountCode = (model: models.CheckoutApplyDiscountViewModel) => executePost<models.HahApiResult<models.CheckoutResultViewModel>>(endpointUrl + 'removeDiscountCode/', model);
    
    export const getCheckoutPaymentViewModel = (authOrLinkedJobsKey: models.AuthOrLinkedJobsKey) => executePost<models.HahApiResult<models.CheckoutPaymentStepReactViewModel>>(endpointUrl + 'getCheckoutPaymentViewModel/', authOrLinkedJobsKey);
    

    

