import { DateTime } from 'luxon';
import * as React from 'react';

interface Props {
    value: string;
    format?: Intl.DateTimeFormatOptions;
}
export const dateTBD = DateTime.local(1, 1, 1);

/**
 * Parses as ISO, then formats the passed in value, returning a locale-specific version of '11/10/2020, 11:20 AM PST'.
 */
export const formatDateTime = (value: string | undefined): string | undefined => {
    if (value) {
        const parsedDt = DateTime.fromISO(value);
        const formattedDate = parsedDt.toLocaleString(DateTime.DATE_SHORT);
        const formattedTime = parsedDt.toLocaleString(DateTime.TIME_SIMPLE);
        const timeZoneShort = parsedDt.toFormat('ZZZZ');

        return formattedDate + ', ' + formattedTime + ' ' + timeZoneShort;
    }
    return undefined;
};

/**
 * Parses as ISO UTC, then formats the passed in value, returning a locale-specific version of '11/10/2020, 11:20 AM PST'.
 */
export const formatDateTimeUtc = (value: string | undefined): string | undefined => {
    if (value) {
        const parsedDt = DateTime.fromISO(value, { zone: 'utc' }).toLocal();

        const formattedDate = parsedDt.toLocaleString(DateTime.DATE_SHORT);
        const formattedTime = parsedDt.toLocaleString(DateTime.TIME_SIMPLE);
        const timeZoneShort = parsedDt.toFormat('ZZZZ');

        return formattedDate + ', ' + formattedTime + ' ' + timeZoneShort;
    }
    return undefined;
};

export const formatDate = (value: string | undefined | DateTime): string | undefined => {
    if (value) {
        const d = DateTime.isDateTime(value) ? value : DateTime.fromISO(value);
        return d.equals(dateTBD) ? 'TBD' : d.toLocaleString(DateTime.DATE_SHORT);
    }
    return undefined;
}

export const formatDateUtc = (value: string | undefined) => value && DateTime.fromISO(value, { zone: 'utc' }).toLocaleString(DateTime.DATE_SHORT);

export const DateTimeDisplay: React.FC<Props> = ({ value }) => <React.Fragment>{value && formatDateTime(value)}</React.Fragment>;

export const DateDisplay: React.FC<Props> = ({ value, format = DateTime.DATE_SHORT }) => <React.Fragment>{value && DateTime.fromISO(value).toLocaleString(format)}</React.Fragment>;


export const dateIsTBD = (value: string | undefined): boolean => {
    return value && DateTime.fromISO(value).equals(dateTBD) || false;
}